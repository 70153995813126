<template>
  <section class=" my-5 py-5" id="Programas" data-aos="fade-down">
    <div class="d-block d-lg-none">
      <div class="text-center">
        <h2><b>{{ data.title }}</b></h2>
        <h6>
          {{ data.subtitle }}
        </h6>
      </div>
      <img
        class="models-image w-100"
        src="@/assets/img/banner-modelos-y-programas-mobile.webp"
        alt="Imagen de inicio de seccion de modelos y programas version movil"
        loading="lazy"
      />
    </div>
    <div class="d-none d-lg-block position-relative">
      <img
        class="w-100 img-fluid"
        src="@/assets/img/banner-modelos-y-programas-desktop.webp"
        alt="Imagen de inicio de seccion de modelos y programas"
        loading="lazy"
      />

      <div class="DivBannerEjecutivo">
        <div class="DivBannerEjecutivoTexto">
          <h2><b>{{ data.title }}</b></h2>
          <h6>
            {{ data.subtitle }}
          </h6>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import store from '../../store';

export default {
  name: "ModeloBanner",
  data() {
    return {
      data: {
        title: 'Modelo y Programas',
        subtitle: 'Te ayudamos a cumplir tus desafíos y construir tu empresa con nosotros.'
      }
    };
  },

  methods: {


    fetchData() {

       if (store.state.sections.challenges) {
      this.data = {...store.state.sections.modelBanner.components}; }
    }

   

  },

  watch: {
    '$store.state.sections': function () {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */

.ModeloBanner {
  position: relative;
}

.DivBannerEjecutivo {
  position: absolute; /* posición absolute con respecto al padre */
  top: 13%;
  width: 40%;
  height: 59%;
  left: 5%;
  /*background-color: #575da6;*/
  /*padding: 1%;*/
  animation: fadeInLeft; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}

.DivBannerEjecutivoTexto {
  padding: 1%;
  margin-top: 24%;
}

.models-image {
  margin-left: -1rem;
}
</style>
